<template>
 <div class="mHome">
     <div class="mBox-teach">
        <img src="https://sc2.hexiaoxiang.com/web_static/new_hx_web/banner-newTeach-v2.jpg" alt="" />
        <!-- <img src="https://sc2.hexiaoxiang.com/web_static/new_hx_web/banner-newTeach-mobile-v1.jpg" />  -->
     </div>
    <div class="mBox">
        <div class="mLeft">
            <h1>河小象六日写字体验包</h1>
            <p>每天15分钟，在家提升练字水平</p>
            <ul>
                <li> 科学专业的写字训练</li>
                <li> 1套科学练字方法</li>
                <li> 全程伴写服务 </li>
            </ul>
            <button class="writeBtn" @click="OpenMask(1)">立即领取</button>
        </div>
        <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/home1.png" class="writeImg">
    </div>
    <!-- <div class="mBox">
        <div class="mRight">
            <h1>每日古诗文</h1>
            <p>每天5分钟，一年吃透300个古诗文名句名段</p>
            <ul>
                <li> 经典诵读AI智能打分 </li>
                <li> 北师大、浙师大硕博士团队研发</li>
                <li> 分年级课程、原创古诗文动画剧、名师权威讲解</li>
            </ul>
            <button class="poetryBtn" @click="OpenMask(2)">立即报名</button>
        </div>
        <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/home2.png" class="poetryImg">
    </div> -->
     <!-- <div class="mBox">
        <div class="mLeft">
            <h1>同步写作300天</h1>
            <p>每日一句，每周一段，每月一篇</p>
            <ul>
                <li> 同步语文教材、贴合考纲</li>
                <li> 分年段、300节在线课程、26册配套实体教材 </li>
                <li> 每月1次一对一写作精点评、限时赠送4次周点评 </li>
            </ul>
            <button class="compositionBtn" @click="OpenMask(3)">立即报名</button>
        </div>
        <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/home3.png" class="compositionImg">
    </div> -->
     <!-- <div class="mBox">
        <div class="mRight">
            <h1>经典名著阅读力</h1>
            <p>系统规划提升7大阅读能力</p>
            <ul>
                <li> 8大经典名著讲解 </li>
                <li> 名著诵读AI智能评测提语感</li>
            </ul>
            <button class="readBtn" @click="OpenMask(4)">立即报名</button>
        </div>
        <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/home4.png" class="readImg">
    </div> -->
    <!-- <div class="mBox">
        <div class="mLeft">
            <h1>河小象美术</h1>
            <p>在家开启艺术之旅</p>
            <ul>
                <li> 专业师资在线1对1点评辅导</li>
                <li> 适合儿童生理心理发展特点 </li>
                <li> 引导启发式教学 </li>
            </ul>
            <button class="artBtn" @click="OpenMask(5)">立即报名</button>
        </div>
        <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/art.png" class="compositionImg">
    </div> -->
    <mTool :downloadShow="showDownload"></mTool>
    <receiveCourse :receiveShow="receiveShow" @closeMask="receiveShow=false"></receiveCourse>
    <div class="mask" v-if="maskshow">
      <div class="maskBox">
        <h3>立即报名</h3>
        <p>长按识别二维码，领取课程</p>
        <img :src="arturl" class="mask-img">
         <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/mask-close.png" class="mask-close" @click.stop="onClose">
      </div>
    </div>
</div>   
</template>
<script>
import mTool from '../components/mTool'
import receiveCourse from '../components/receiveCourse'
export default {
    name: 'mHome',
    data() {
        return {
            showDownload: false,
            maskshow:false,
            arturl:'',
            receiveShow:false
        }
    },
    methods:{
        OpenMask (flag) {
            if (flag == 1) {
                if(localStorage.getItem("source")){
                    if (localStorage.getItem("source").indexOf("bdss") != -1) {
                        window.location.href ="https://xcx.hexiaoxiang.com/view/signin?referId=44073";
                    } else {
                        window.location.href ="https://xcx.hexiaoxiang.com/statich5/hx/tmk-sign.html?referSource=baidugw";
                    }
                }else{
                        window.location.href ="https://xcx.hexiaoxiang.com/statich5/hx/tmk-sign.html?referSource=baidugw";
                }
            } else if (flag == 2) {
              this.receiveShow=true
                // window.location.href = "https://yw.hexiaoxiang.com/goods/purchase.html#/?productId=10";
            } else if (flag == 3) {
               this.receiveShow=true
                // window.location.href = "https://yw.hexiaoxiang.com/goods/purchase.html#/?productId=12";
            } else if (flag == 4) {
               this.receiveShow=true
                // window.location.href = "https://yw.hexiaoxiang.com/goods/purchase.html#/?productId=22";
            }else if(flag==5){
              this.receiveShow=true
              // this.maskshow=true
              // this.arturl="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/m-art.png"
            }
        },
        onClose(){
          this.maskshow=false
        }
    },
    components:{
        mTool,
        receiveCourse
    },
}

</script>
<style lang="scss" scoped>
.mHome{
    & .mBox:nth-child(2n+1){
        background-color: #F6F6F9;
    }
    .mBox:nth-child(2n+2){
         background-color: #fff;
    }
    .mBox:nth-child(1){
        ul li{
            background-image: url("https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon-mobile-orange.png");
            background-size: .3rem auto;
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: .4rem;
        }
    }
    .mBox:nth-child(2n){
        ul li{
            background-image: url("https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon-mobile-green.png");
            background-size: .3rem auto;
            background-repeat: no-repeat;
            background-position: right center;
            padding-right: .4rem;
        }
    }
    .mBox:nth-child(3){
        ul li{
            background-image: url("https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon-mobile-blue.png");
            background-size: .3rem auto;
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: .4rem;
        }
    }
    .mBox-teach{
        img{
            width: 100%;
        }
    }
    .mBox{
        padding: .96rem .48rem .827rem;
         h1{
            font-size: .587rem;
            color: #444144;
            font-weight: bold;
        }
        p{
            color: #444144;
            font-size: .373rem;
            margin: .24rem 0 0;
        }
        ul{
            font-size: .373rem;
            color: #444144;
            font-family:PingFang-SC-Regular;
            margin-top: .8rem;
            li{
                line-height: .72rem;
            }
        }
        .mLeft{
        text-align: left;
        .writeBtn{
            width: 3.067rem;
            height: .907rem;
            background:linear-gradient(270deg,rgba(248,120,51,1) 0%,rgba(255,139,53,1) 100%);
            box-shadow:0 .213rem .48rem 0 rgba(252,114,46,0.32);
            border-radius: .453rem;
            text-align: center;
            line-height: .907rem;
            font-size: .373rem;
            color: #fff;
            margin-top: 2.56rem;
            
        }
        .compositionBtn{
             width: 3.067rem;
            height: .907rem;
            background:linear-gradient(270deg,rgba(95,183,251,1) 23%,rgba(113,217,253,1) 100%);
            box-shadow:0 .213rem .48rem 0 rgba(90,202,255,0.32);
            border-radius: .453rem;
            text-align: center;
            line-height: .907rem;
            font-size: .373rem;
            color: #fff;
            margin-top: 2.56rem;
        }
        .artBtn{
            width: 3.067rem;
            height: .907rem;
            background:linear-gradient(90deg,rgba(255,216,0,1) 0%,rgba(255,185,0,1) 100%);
            box-shadow:0 .213rem .48rem 0 rgba(255,186,0,0.32);
            border-radius: .453rem;
            text-align: center;
            line-height: .907rem;
            font-size: .373rem;
            color: #fff;
            margin-top: 2.56rem;
        }
        
    }
        .writeImg{
            float: right;
            height: 4.133rem;
            margin-top: -4rem
        }
        .poetryImg{
            float: left;
            height: 4.133rem;
            margin-top: -3.6rem;
        }
        .compositionImg{
            float: right;
            height: 3.6rem;
            margin-left: -0.027rem;
            margin-top: -3.5rem
        }
          .readImg{
            float: left;
            height: 4rem;
            margin-top: -4.4rem
        }
        .mRight{
            text-align: right;
        .poetryBtn{
                width: 3.067rem;
                height: .907rem;
                background:linear-gradient(90deg,rgba(101,238,91,1) 0%,rgba(75,216,59,1) 100%);
                box-shadow:0 .213rem .48rem 0 rgba(77,218,62,0.32);
                border-radius: .453rem;
                text-align: center;
                line-height: .907rem;
                font-size: .373rem;
                color: #fff;
                margin-top: 2.573rem;
            }
            .readBtn{
                 width: 3.067rem;
                height: .907rem;
                background:linear-gradient(90deg,rgba(255,134,241,1) 0%,rgba(181,97,193,1) 100%);
                box-shadow:0 .213rem .48rem 0 rgba(186,59,204,0.32);
                border-radius: .453rem;
                text-align: center;
                line-height: .907rem;
                font-size: .373rem;
                color: #fff;
                margin-top: 3.8rem;
            }
        }
    } 
.mask{
    position: fixed;
    top:0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background:rgba(0,0,0,0.6); 
  }
  .maskBox{
    width: 8.04rem;
    height: 6.773rem;
    border-radius: .427rem;
    background: #fff;
    margin: 4rem auto;
    position: relative;
  }
  .mask-close{
    position: absolute;
    width: .64rem;
    height: .64rem;
    right: .267rem;
    top: .267rem;
  }
  .maskBox h3{
    color: #FA6400;
    font-size: .48rem;
    padding: .64rem 0 .267rem;
  }
  .maskBox p{
    font-size: .427rem;
    color: #2D2E3F;
  }
  .mask-img{
    width: 3.2rem;
    height: 3.2rem;
    margin-top: .453rem;
  }
}
</style>
